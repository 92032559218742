import React, { useRef, useState } from "react";
import qs from "qs";
import { isBrowser } from "@emotion/core/src/utils";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite"
import styled from "@emotion/styled";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Footer from "../components/Context/Footer";
import HeroSearch from "../components/General/HeroSearch";
import Results from "../components/Search/Results";

const initialQuery = isBrowser ? qs.parse(window.location.search, {ignoreQueryPrefix: true}).query : "";

const Root = styled.div`
position:relative;
`;

const Search = () => {
    const ref = useRef();
    const theme = "dark";
    const [query, setQuery] = useState(initialQuery);
    const searchClient = algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )
    return (
        <Layout>
            <SEO title={query ? `Results for ${query}` : "Search"}/>
            <Header theme={theme} />
            <InstantSearch
                searchClient={searchClient}
                searchState={{query}}
                indexName={process.env.GATSBY_ALGOLIA_SEARCH_INDEX}
                onSearchStateChange={({ query }) => setQuery(query)}
                root={{ Root, props: { ref } }}
            >
                <HeroSearch searchTerm={query} theme={theme} />
                <Results theme={theme} />
            </InstantSearch>
            <Footer theme={theme} />
        </Layout>
    );
};

export default Search;