import React from "react";

const SearchSVG = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4833 20.6561C25.058 18.5057 25.9999 15.8644 25.9999 13.001C25.9999 5.83301 20.1678 0.000976562 12.9999 0.000976562C5.83197 0.000976562 0 5.83301 0 13.001C0 20.1689 5.83203 26.0009 13 26.0009C15.8634 26.0009 18.505 25.0589 20.6553 23.4842L29.1719 32.0008L32 29.1727C32 29.1726 23.4833 20.6561 23.4833 20.6561V20.6561ZM13 22.0009C8.03709 22.0009 4.00002 17.9638 4.00002 13.001C4.00002 8.03806 8.03709 4.00099 13 4.00099C17.9629 4.00099 21.9999 8.03806 21.9999 13.001C21.9999 17.9638 17.9628 22.0009 13 22.0009Z" />
        </svg>
    );
};

export default SearchSVG;