import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import Container from "../Context/Container";
import Section from "../Context/Section";
import ArticleBlock from "../General/ArticleBlock";
import Article from "../General/Article";
import SpinnerSVG from "../SVGs/SpinnerSvg";
import {
    Index,
    connectStateResults,
    connectHits
} from "react-instantsearch-dom";

const StatsWrapper = styled.div`
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }
`;

const Loading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Spinner = styled.div`
    border-radius: 50%;
    animation: 1.2s linear 0s infinite running spin;
    width: 50px;
    height: 50px;

    svg{
        fill: ${color("dark")};
        stroke: ${color("dark")};
        width: 100%;
        height: 100%;
    }

    @keyframes spin{
        0%{
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Standard = styled.div`
    p{
        ${typography.label};
        text-transform: none;
    }

    border-bottom: 1px solid ${color("grey")};
    padding-bottom: 8px;

    @media screen and (min-width: 1200px) {
        padding-bottom: 16px;
    }
`;

const searchCategoryByType = {
    "news": "News",
    "news_listing": "News",
    "insights_landing": "Insights",
    "project": "Projects",
    "news_article": "News",
    "insight": "Insights"
}

const Stats = connectStateResults(
    ({ searchState: state, searchResults: res }) =>
        res && res.nbHits > 0 && (
            <StatsWrapper>
                <Standard>
                    <p>Showing <strong>{res.nbHits}</strong> result{res.nbHits > 1 ? `s` : ``} for '<strong>{state.query}</strong>'</p>
                </Standard>
            </StatsWrapper>
        )
)
const Results = connectStateResults(
    ({ searchState: state, searchResults: res, children, isSearchStalled}) => {
        return res && res.nbHits > 0 ? children : (
            <StatsWrapper>
                <Standard>
                    {isSearchStalled ? <Loading><Spinner><SpinnerSVG /></Spinner></Loading> : <p>No results for '<strong>{state.query}</strong>'</p>}
                </Standard>
            </StatsWrapper>
        );
    }
)

const SearchResults = ({ hits }) => (
    <>
        {hits.map(hit => {
            let description = null;

            const {meta_description, summary, intro, introduction, tagline, name, title, external_article_link} = hit.data;

            if(meta_description){
                description = `<p>${meta_description}</p>`;
            }else if(summary){
                description = `<p>${summary.text}</p>`;
            }else if(introduction){
                description = `<p>${introduction.text}</p>`;
            }else if(intro || tagline){
                let descriptionContent;
                if(intro){
                    descriptionContent = intro;
                }else{
                    descriptionContent = tagline;
                }
                if(descriptionContent.text){
                    description = `<p>${descriptionContent.text}</p>`;
                }else if(descriptionContent.html){
                    description = descriptionContent.html;
                }else if(typeof descriptionContent !== "object"){
                    description = `<p>${descriptionContent.tagline}</p>`;
                }
            }

            return (
                <Article
                    title={name?.text ?? title?.text}
                    description={description}
                    url={external_article_link && external_article_link.url ? external_article_link.url : hit.url}
                    internal={external_article_link && external_article_link.url ? false : true}
                    key={hit.objectID}
                    search={true}
                    type={external_article_link && external_article_link.url ? `News (External)`
                        : searchCategoryByType[hit.type] ?? "Page"}
                />
            )
        })}
    </>
);

const Hits = connectHits(SearchResults);

const ResultsComponent = ({theme}) => {
    return (
        <Section paddingS="0 0 64px" paddingM="0 0 72px" paddingL="0 0 105px" paddingXL="0 0 120px" paddingXXL="0 0 145px" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container>
                <Index indexName={process.env.GATSBY_ALGOLIA_SEARCH_INDEX}>
                    <Stats />
                    <Results>
                        <ArticleBlock
                            theme={theme}
                            articlesComponents={<Hits />}
                            search={true}
                        />
                    </Results>
                </Index>
            </Container>
        </Section>
    );
};

export default ResultsComponent;
