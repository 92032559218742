import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import Container from "../Context/Container";
import Section from "../Context/Section";
import { connectSearchBox } from "react-instantsearch-dom";
import SearchSVG from "../SVGs/SearchSvg";

const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: 72px;
    position: relative;

    button{
        ${typography.h2};
        position: absolute;
        right: 0;
        top: -2px;
        width: 20px;
        padding: 0;
        border: none;
        background: none;
        cursor: hand;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 0.1em;

        svg{
            fill: ${color("grey")};
            transition: fill 0.2s ease;
            width: 100%;
        }

        &:focus{
            outline: none;
        }

        input:focus{
            background: yellow;
        }

        input:focus + button{
            background: green;
        }

        &:hover{
            svg{
                fill: ${color("dark")};
            }
        }
    }

    @media screen and (min-width: 768px) {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 105px;

        button{
            width: 25px;
            top: -4px;
        }
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 120px;

        button{
            right: 38px;
            top: -6px;
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 160px;

        button{
            right: 58px;
            width: 32px;
            top: -8px;
        }
    }
`;

const SearchInput = styled.input`
    ${typography.h2};
    border: none;
    background: none;
    border-bottom: 1px solid ${color("dark")};
    width: 100%;
    margin-bottom: 0;
    padding: 0 0 2px;
    padding-right: 50px;
    box-sizing: border-box;
    border-radius: 0;

    &:focus{
        outline: none;

        & + button{
            svg{
                fill: ${color("dark")};
            }
        }
    }

    &::placeholder{
        color: ${color("grey")};
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        padding-right: 55px;
        padding-bottom: 4px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 0;
        padding-bottom: 6px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 0;
        padding-right: 62px;
        padding-bottom: 8px;
    }
`;

const Input = connectSearchBox(({ refine, ...rest }) => {
    const submitForm = (e) => {
        e.preventDefault();
    }

    return (
        <form
            onSubmit={submitForm}
        >
            <SearchInput
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={e => refine(e.target.value)}
                {...rest}
            />
            <button>
                <SearchSVG onClick={submitForm} />
            </button>
        </form>
    )
});


const HeroSearch = ({searchTerm, theme}) => {
    return (
        <Section paddingS="169px 0 0" paddingM="333px 0 0" paddingL="278px 0 0" paddingXL="388px 0 0" paddingXXL="402px 0 0" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container>
                <InputWrapper>
                    <Input defaultValue={searchTerm} />
                </InputWrapper>
            </Container>
        </Section>
    );
};

export default HeroSearch;
